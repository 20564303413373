import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import visitorService from "../../services/visitorService";

export const trackCustomerOrder = createAsyncThunk("/order/trackCustomerOrder", async (orderData) => {
    try {
        const response = await visitorService.trackCustomerOrder(orderData);
        const data = await response.data;
        return data;
    } catch (error) {
        return error.response.data;
    }
}
);

export const getDownloadHistory = createAsyncThunk("/order/getDownloadHistory", async (orderId) => {
    try {
        // console.log("getDownloadHistory", getDownloadHistory);
        const response = await visitorService.getDownloadHistory(orderId);
        const data = await response.data;
        return data;
    } catch (error) {
        return error.response.data;
    }
}
);

export const downloadPassportDocPdf = createAsyncThunk("/order/downloadPassportDocPdf", async (orderId) => {
    try {
        // console.log("inside downloadPassportDocPdf");
        // alert("inside downloadPassportDocPdf");
        const response = await visitorService.downloadPassportDocPdf(orderId);
        // console.log("response downloadPassportDocPdf", response);
        // alert(response);
        const data = await response.data;
        return data;
    } catch (error) {
        console.log("error", error.response.data);
        return error.response.data;
    }
}
);

export const visitorReducer = createSlice({
    name: "vistor",
    initialState: {
        loading: false,
        error: null,
        showNavbar: false,
        orderIDs: null,
        downloadHistoryList: {},
        mainResult: {},
        downloadFilePath: {},
    },
    reducers: {
        toggleNavbar: (state) => ({
            ...state,
            showNavbar: !state.showNavbar,
        }),
    },

    extraReducers: (builder) => {
        builder.addCase(getDownloadHistory.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getDownloadHistory.fulfilled, (state, action) => {
            state.loading = false;
            state.downloadHistoryList = action?.payload?.data;
            state.mainResult = action?.payload?.mainResult;
            state.downloadFilePath = action?.payload?.path;
            // console.log("action", action?.payload);
        });
        builder.addCase(getDownloadHistory.rejected, (state, action) => {
            state.loading = false;
        });
    },
});

export const { toggleNavbar } = visitorReducer.actions;

export default visitorReducer.reducer;
