import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import GateWayService from "../../services/GatewayService";

export const getPaymentGateways = createAsyncThunk("/admin/payment-gateways",async () => {
    try {
      const response = await GateWayService.getPaymentGateways();
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const updatePaymentGateway = createAsyncThunk("/admin/payment-gateways-setting",async (gatewayDetails) => {
    try {
      const response = await GateWayService.updatePaymentGateway({gatewayDetails});
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const gateWaySlice = createSlice({
    name: "gateway",
    initialState: {
        loading: false,
        error: null,
        paymentGateways: [],
    },
    reducers: {},

    extraReducers: (builder) => {
    builder.addCase(getPaymentGateways.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPaymentGateways.fulfilled, (state, action) => {
      state.loading = false;
      state.paymentGateways = action?.payload?.data;
    });
    builder.addCase(getPaymentGateways.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default gateWaySlice.reducer;
