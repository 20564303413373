import React, { useEffect } from "react";
import { StyledContainer, StyledGatewayBlock } from "./style";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getPaymentGateways,
  updatePaymentGateway,
} from "../../features/paymentgateway/gateWaySlice";
import { encryptVal } from "../../utils/utility";

const GatewaySetting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const paymentGateways = useSelector((state) => state?.gateway?.paymentGateways);

  useEffect(() => {
    dispatch(getPaymentGateways()).unwrap().then((res) => {
      if (res.status === 401) {
        toast.error(`${res?.message}`, { className: "toast-message" });
        localStorage.removeItem("user");
        navigate("/");
      }
    });
    document.title = "Payment Setting | UK E-visa";
  }, [dispatch, navigate]);

  const GatewaySchema = yup.object({
    gatewayName: yup.string().required("Please select a gateway."),
  });

  const primaryFormik = useFormik({
    initialValues: { gatewayName: "" },
    validationSchema: GatewaySchema,
    onSubmit: (values) => {
        console.log("values ",values.gatewayName);
        console.log("encrypted values ",encryptVal(values.gatewayName));
      dispatch(updatePaymentGateway({
        gatewayId: encryptVal(values.gatewayName),
        isSecondaryDomain: 0
      }))
        .unwrap()
        .then((res) => {
          res.status === 1
            ? toast.success(`${res.message}`, { className: "toast-message" })
            : toast.error(`${res.message}`, { className: "toast-message" });
        });
    },
  });

  const secondaryFormik = useFormik({
    initialValues: { gatewayNameSecondary: "" },
    validationSchema: yup.object({
      gatewayNameSecondary: yup.string().required("Please select a gateway."),
    }),
    onSubmit: (values) => {
        console.log("values ",values.gatewayNameSecondary);
        console.log("encrypted values ",encryptVal(values.gatewayNameSecondary));
      dispatch(updatePaymentGateway({
        gatewayId: encryptVal(values.gatewayNameSecondary),
        isSecondaryDomain: 1
    })).unwrap()
    .then((res) => {
    res.status === 1
        ? toast.success(`${res.message}`, { className: "toast-message" })
        : toast.error(`${res.message}`, { className: "toast-message" });
    });
    },
  });

  useEffect(() => {
    console.log("paymentGateways ",paymentGateways);
    const activeGateway = paymentGateways?.find((item) => item.is_active === "1" && item.is_secondary_domain === 0);
    const secondaryActiveGateway = paymentGateways?.find((item) => item.is_active === "1" && item.is_secondary_domain === 1 );
    if (activeGateway) {
      primaryFormik.setFieldValue("gatewayName", activeGateway.id);
    }
    if(secondaryActiveGateway) {
        secondaryFormik.setFieldValue("gatewayNameSecondary", secondaryActiveGateway.id);
    }
  }, [paymentGateways]);

  return (
    <StyledContainer>
      <div className="row">
        <div className="col-md-6">
          <StyledGatewayBlock>
            <h2>Change Gateway (UK-ETA.ONLINE)</h2>
            <div className="table-block table-responsive">
              <form onSubmit={primaryFormik.handleSubmit}>
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td style={{ width: "30%" }}>Gateway</td>
                      <td style={{ width: "70%" }}>
                        <select
                          className="form-control gateway_name font-normal"
                          name="gatewayName"
                          value={primaryFormik.values.gatewayName}
                          onChange={primaryFormik.handleChange}
                          onBlur={primaryFormik.handleBlur}
                        >
                          <option>Select Gateway</option>
                         {/* Primary Gateway Dropdown (for is_secondary_domain === 0) */}
                            {paymentGateways
                            ?.filter(item => item.is_secondary_domain === 0)
                            .map((gateway, index) => (
                                <option value={gateway?.id} key={index}>
                                {gateway?.gateway_name}
                                </option>
                            ))}
                        </select>
                        <p>{primaryFormik.errors.gatewayName}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <button type="submit" className="btn blue-btn">
                          Active Gateway
                        </button>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </StyledGatewayBlock>
        </div>
        <div className="col-md-6">
          <StyledGatewayBlock>
            <h2>Change Gateway (ETA-UK.SITE)</h2>
            <div className="table-block table-responsive">
              <form onSubmit={secondaryFormik.handleSubmit}>
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td style={{ width: "30%" }}>Gateway</td>
                      <td style={{ width: "70%" }}>
                        <select
                          className="form-control gateway_name font-normal"
                          name="gatewayNameSecondary"
                          value={secondaryFormik.values.gatewayNameSecondary}
                          onChange={secondaryFormik.handleChange}
                          onBlur={secondaryFormik.handleBlur}
                        >
                          <option>Select Gateway</option>
                            {/* Secondary Gateway Dropdown (for is_secondary_domain === 1) */}
                            {paymentGateways
                            ?.filter(item => item.is_secondary_domain === 1)
                            .map((gateway, index) => (
                                <option value={gateway?.id} key={index}>
                                {gateway?.gateway_name}
                                </option>
                            ))}
                        </select>
                        <p>{secondaryFormik.errors.gatewayNameSecondary}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <button type="submit" className="btn blue-btn">
                          Active Gateway
                        </button>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </StyledGatewayBlock>
        </div>
      </div>
    </StyledContainer>
  );
};

export default GatewaySetting;
