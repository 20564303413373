import React, { useState } from "react";
import { StyledOrderBlock } from "../style";
import { addNewRemark } from "../../../features/remark/remarkSlice";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getFolderCount,
  getOrderDetailsByOrderId,
  getOrdersList,
  moveOrderStatus,
} from "../../../features/order/orderSlice";
import { jwtDecode } from "jwt-decode";

const initialValues = {
  remark: "",
  whatsapp: "",
  telephone: "",
  statusRefund: "",
  voided: "",
  chargeBack: "",
};

const remarkSchema = yup.object({
  remark: yup.string().required("Please enter your remark"),
  whatsapp: "",
  telephone: "",
  statusRefund: "",
  voided: "",
  chargeBack: "",
});

const AddComment = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const OrderDetails = useSelector((state) => state?.order?.orderDetails);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const token = localStorage.getItem("user");

  let userDetails;

  if (token !== null) {
    const decodedToken = jwtDecode(token);
    userDetails = decodedToken?.user;
  }

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: remarkSchema,
    onSubmit: (values) => {
      let remarkDataArr = {
        orderId: OrderDetails?.order_id,
        remark: values.remark,
        remarkerName: userDetails?.name,
        whatsapp: values.whatsapp,
        telephone: values.telephone,
        statusRefund: values.statusRefund,
        voided: values.voided,
        chargeBack: values.chargeBack,
      };
      dispatch(addNewRemark(remarkDataArr))
        .unwrap()
        .then((res) => {
          resetForm(initialValues);
          if (res.status === 1) {
            toast.success(`${res.message}`, {
              className: "toast-message",
            });
            dispatch(getOrderDetailsByOrderId(orderId));
          }
        })
        .catch((err) => {
          if (err.status === 0) {
            toast.error(`${err.message}`, {
              className: "toast-message",
            });
          }
        });
    },
  });

  const handleOrderStatus = (e) => {
    const data = {
      orderId: OrderDetails?.order_id,
      // permanentDeletedFlag: false,
      updatingStatus: e.target.value,
      // email: OrderDetails?.email,
    };
    dispatch(moveOrderStatus(data))
      .unwrap()
      .then((res) => {
        if (res.status === 1) {
          toast.success(`${res.message}`, {
            className: "toast-message",
          });
          e.target.blur();
          dispatch(getFolderCount());
          dispatch(
            getOrdersList({
              orderName: "refundorder",
              page: page,
              perPage: limit,
            })
          );
        }
      });
  };

  console.log("OrderDetails", OrderDetails);

  return (
    <StyledOrderBlock>
      <h2>Add Comment Below</h2>
      <form onSubmit={handleSubmit}>
        <textarea
          name="remark"
          style={{ display: "inline-block" }}
          className="form-control"
          defaultValue={values.remark}
          onChange={handleChange}
          onBlur={handleBlur}
        ></textarea>
        <p>{errors.remark}</p>

        <label>
          {" "}
          <input type="hidden" name="check" value="" className="form-control" />
        </label>
        <div className="checkbox">
          <label htmlFor="whatsapp-id" className="label-bold">
            <input
              type="checkbox"
              name="whatsapp"
              id="whatsapp-id"
              onChange={() => setFieldValue("whatsapp", "Y")}
            />
            <span></span>
            Whatsapp
          </label>

          <label htmlFor="telephone-id" className="label-bold">
            <input
              type="checkbox"
              name="telephone"
              id="telephone-id"
              onChange={() => setFieldValue("telephone", "Y")}
            />
            <span></span>
            Telephone
          </label>

          <label htmlFor="statusRefund-id" className="label-bold">
            <input
              type="checkbox"
              name="statusRefund"
              id="statusRefund-id"
              onChange={() => setFieldValue("statusRefund", "Y")}
            />
            <span></span> Refund
          </label>

          <label htmlFor="voided-id" className="label-bold">
            <input
              type="checkbox"
              name="voided"
              id="voided-id"
              onChange={() => setFieldValue("voided", "Y")}
            />
            <span></span>
            Voided
          </label>

          <label htmlFor="chargeback-id" className="label-bold">
            <input
              type="checkbox"
              name="chargeBack"
              id="chargeback-id"
              onChange={() => setFieldValue("chargeBack", "Y")}
            />
            <span></span>
            Chargeback
          </label>
        </div>
        <button type="submit" className="blue-btn" value="comment">
          Save Comment
        </button>
        {(OrderDetails?.process_status !== "Awaiting ") && (

          <button
            type="button"
            className="blue-btn"
            value="Awaiting "
            id="Awaiting  Response"
            onClick={handleOrderStatus}
          >
            Move to Awaiting Response
          </button>
        )}
        {(OrderDetails?.process_status !== "Refunds") && (
          <button
            type="button"
            className="blue-btn"
            value="Refunds"
            id="Refunds"
            onClick={handleOrderStatus}
          >
            Move to Completed Refunds
          </button>
        )}

        {(OrderDetails?.process_status !== "Completed") && (
          <button
            type="button"
            className="blue-btn"
            value="Completed"
            id="Completed"
            onClick={handleOrderStatus}
          >
            Move to Completed Orders
          </button>
        )}
        {(OrderDetails?.process_status !== "Deleted") && (
          <button
            type="button"
            className="blue-btn"
            value="Deleted"
            id="Deleted"
            onClick={handleOrderStatus}
          >
            Move to Delete
          </button>
        )}
        {(OrderDetails?.process_status !== "Rejected") && (
          <button
            type="button"
            className="blue-btn"
            value="Rejected"
            id="Rejected"
            onClick={handleOrderStatus}
          >
            Move to Rejected
          </button>
        )}
        {/* {(OrderDetails?.is_secondary_domain != 1) && ( */}
        <button
          type="button"
          className="blue-btn"
          value="moveToProcessMarch"
          id="moveToProcessMarch"
          onClick={handleOrderStatus}
        >
          Move to Process 5th March Month
        </button>
        {/* )} */}
        {(OrderDetails?.process_status !== "Contact Customer") && (
          <button
            type="button"
            className="blue-btn"
            value="Contact Customer"
            id="Contact Customer"
            onClick={handleOrderStatus}
          >
            Move to Contact Customer
          </button>
        )}

        {(OrderDetails?.process_status === "New" ||
          OrderDetails?.process_status === "Priority Processing" ||
          OrderDetails?.process_status === "moveToProcessMarch" ||
          OrderDetails?.process_status === "Awaiting ") && (
            <button
              type="button"
              className="blue-btn"
              value="AwaitingGovt"
              id="AwaitingGovt"
              onClick={handleOrderStatus}
            >
              Move to Awaiting Govt
            </button>
          )}

        {OrderDetails?.process_status === "Contact Customer" && (
          <button
            type="button"
            className="blue-btn"
            value="Customer Called"
            id="Customer Called"
            onClick={handleOrderStatus}
          >
            Move to Customer Called
          </button>
        )}
        {OrderDetails?.process_status !== "ChargeBack" && (
          <button
            type="button"
            className="blue-btn"
            value="ChargeBack"
            id="ChargeBack"
            onClick={handleOrderStatus}
          >
            Move to ChargeBack
          </button>
        )}

      </form>
    </StyledOrderBlock>
  );
};

export default AddComment;
