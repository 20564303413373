import React from "react";
import { Link } from "react-router-dom";
import { EmailContainer, StyleLogo } from "./style";
import { useSelector } from "react-redux";
// import { encryptVal } from "../../../utility/utility";
import AdminLogo from "../../../assets/images/logo.jpg";
import { encryptVal } from "../../../utils/utility";
const EmailContent = () => {
  const OrderDetails = useSelector((state) => state?.order?.orderDetails);
  const baseUrl = process.env.REACT_APP_URL;
  const url = `${baseUrl}/track-order/${encryptVal(OrderDetails?.order_id)}`;
  const linkToDownloadDoc = url;
  const domainName = OrderDetails?.order_id?.substring(0, 6);

  return (
    <EmailContainer>
      <StyleLogo>
        <img
          src={AdminLogo}
          alt="AdminLogo"
          styel={{ padding: " -37px", width: "130px" }}
        />
      </StyleLogo>
      <hr />
      <p>
        <b>{`Dear : ${OrderDetails?.full_name ? OrderDetails?.full_name : `${OrderDetails?.first_name} ${OrderDetails?.sur_name}`}`}</b>
      </p>
      <p>We would like to inform you that your UK eTA application has been successfully approved.</p>
      <p>
        Please download your eTA document from the link below.
      </p>

      <p>Username : <a href={OrderDetails?.contact_email_address ? OrderDetails?.contact_email_address : ''}>{OrderDetails?.contact_email_address ? OrderDetails?.contact_email_address : ''}</a></p>
      <p>
        Order Id : {OrderDetails?.order_id}
      </p>

      <p
        style={{
          background: "#007bff",
          border: "1px solid #007bff",
          padding: "10px 5px",
          color: "#fff",
          transition: "0.4s",
          textDecoration: "none", // Fixed camelCase
          display: "inline-block", // Ensures proper spacing
        }}
      >
        <a
          href={linkToDownloadDoc}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#fff", textDecoration: "none" }} // Ensure text color stays white
        >
          Click here
        </a>
      </p>

      <p>
        <b>Important Notice:</b>
      </p>


      <ul>
        <li>You can get an ETA instead of a visa if you're: coming to the UK for up to 6 months for tourism, visiting family and friends, business. coming to the UK for up to 3 months transiting through the UK.</li>
        <li>Everyone travelling needs to get an ETA, including babies and children. You can apply for other people.</li>
        <li>You'll usually get a decision within 3 working days, but you may get a quicker decision. Occasionally, it may take longer than 3 working days.</li>
        <li>You must apply for an ETA before you travel to the UK. You can travel to the UK while waiting for a decision.</li>
        <li>You'll get an email confirming you've got an ETA. We'll link it to the passport you applied with.</li>
        <li>Your ETA lasts for 2 years. You can travel to the UK as many times as you want during that time.</li>
        <li>If you get a new passport, you'll need to get a new ETA.</li>
      </ul>


      <p>We remain at your disposal for any further questions or assistance you may need regarding your UK eTA</p>
      <p>Customer Service Number : +1 (407) 305 3733</p>

      <p>Important Notice:</p>
      <p>If you are not satisfied with the service you have received or wish to file a complaint, please contact us at {domainName == 'eTA-UK' ? <a href='mailto:Inquiries@uk-eta.online'>Inquiries@uk-eta.online</a> : <a href='mailto:inquiries@eta-uk.site'>inquiries@eta-uk.site</a>} , where we will be happy to assist you or alternatively click on one of the links below for more information.</p>
      <p>You can access the terms and conditions you agreed to by clicking here {domainName == 'eTA-UK' ? <Link to="https://uk-eta.online/terms_condition.php">click here</Link> : <Link to="https://eta-uk.site/terms_condition.php">click here</Link>} </p>

      <p>Thank you for using our services</p>
      <p> Regards,</p>
      <p> Processing Department</p>

      <p>  {domainName == 'eTA-UK' ? <Link to="https://uk-eta.online/terms_condition.php">Terms</Link> : <Link to="https://eta-uk.site/terms_condition.php">Terms</Link>} |{" "}
        {domainName == 'eTA-UK' ? <Link to="https://uk-eta.online/privacy_policy.php">Privacy</Link> : <Link to="https://eta-uk.site/privacy_policy.php">Privacy</Link>} |{" "}
        {domainName == 'eTA-UK' ? <Link to="https://uk-eta.online/contact_us.php">Contact</Link> : <Link to="https://eta-uk.site/contact_us.php">Contact</Link>} |{" "}
        {domainName == 'eTA-UK' ? <Link to="https://uk-eta.online/refund_policy.php">Refund</Link> : <Link to="https://eta-uk.site/refund_policy.php">Refund</Link>}
      </p>

    </EmailContainer>
  );
};

export default EmailContent;
